<template>
  <v-card>
    <v-card-title
      class="headline primary dark white--text"
      primary-title
    >
      Batch No. {{batchDetails.batch_number}} details
      <v-spacer/>
      <v-btn icon dark @click="printDM()" v-if="batchDetails.document_type == 'DM'">
        <v-icon>mdi-printer-eye</v-icon>
      </v-btn>
      <v-btn icon dark @click="printCheque()" v-else-if="batchDetails.document_type == 'CV'">
        <v-icon>mdi-printer-eye</v-icon>
      </v-btn>
      <v-btn icon dark @click="closeDialog(batchDetails.batch_number)">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text v-if="batchDetails">
      <br>
      <v-layout>
        <v-flex xs12 sm12 md4>
         <h2>{{getTrxType(batchDetails.document_type)}} Information:</h2>
         <br>
         <h3>Date: {{documentDetails[0].document_date}}</h3>
         <h3>Batch No: {{batchDetails.batch_number}}</h3>
         <h3>Document Type: {{getDocuType(batchDetails.document_type)}}</h3>
         <br>
        </v-flex>
        <v-spacer/>
        <v-flex xs12 sm12 md6>
          <h3>Attached Files:</h3>
          <v-layout column style="height: 100px">
            <v-flex style="overflow: auto" class="elevation-3">
              <v-data-table
                :headers="attachHeaders"
                :items="trxdisbDetailed"
                :loading="false"
                class="elevation-1"
                :hide-default-footer="true"
                :hide-default-header="true"
                style="margin:0"
				:items-per-page="1000"
              >
                <template v-slot:items="props">
                  <td>{{ props.item.title }}</td>					
                </template>
                <template v-slot:[`item.file`]="{ item }">
                  <v-icon class="text-right mr-2" :color="item.seen ? 'gray' : 'primary'" medium @click="viewItem(item.path)"
                    >mdi-eye-circle</v-icon
                  >
                </template>
              </v-data-table>
            </v-flex>
          </v-layout>
          <div class="container" v-if="batchDetails.status == 6">
						<div class="large-12 medium-12 small-12 cell">
							<label>
								<p>Releasing Files:</p>
							</label>
							<label>
								<input type="file" id="files" ref="files" multiple v-on:change="handleFileUploads()"/>
							</label>
						</div>
					</div>
					<div class="container" v-if="batchDetails.document_type === 'CW' && batchDetails.status === 6">
						<div class="large-12 medium-12 small-12 cell">
							<label>
								<p>Partner Bank File Upload:</p>
							</label>
							<label>
								<input type="file" id="partnerBankFiles" ref="partnerBankFiles" v-on:change="handleTextFileUpload()"/>
							</label>
						</div>
					</div>
        </v-flex>
      </v-layout>
      <v-layout>
        <v-flex xs12 sm12 md4>
          
        </v-flex>
      </v-layout>
      <h2>Accounting Entries</h2>
      <v-container class="doc-items-container" grid-list-md>
        <p class="caption"></p>
        <v-row justify="center" v-if="documentDetails.length > 0">
          <v-container outlined>
            <v-card outlined
              v-for="(doc_detail) in documentDetails" :key="doc_detail.key"
            >
              <template>
                <v-card-title dark class="document-card-title grey lighten-2">
                  Document No. {{(doc_detail.document_type === 'CW') ? doc_detail.reference_number : doc_detail.document_number}}
                </v-card-title>
              </template>
              <v-container>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12 sm6 md6>
                      <h4>Document Number: {{(doc_detail.document_type === 'CW') ? doc_detail.reference_number : doc_detail.document_number}}</h4>
                      <h4 v-if="doc_detail.document_type !== 'JE' && doc_detail.document_type !== 'CR'">Cheque Number: {{doc_detail.cheque_number}}</h4>
                      <h4>Payee: {{ getValue(payees, "id", doc_detail.payee_id, "payee_desc") }}</h4>
                    </v-flex>
                    <v-flex xs12 sm6 md6>
                      <h4>Transaction Type:  {{ getTransType(doc_detail.disbursement_type) }}</h4>
                      <h4>ATC Code: {{ getValue(alphataxcodes, "atc_code", doc_detail.atc_code, "atc_desc") }}</h4>
                      <h4>ATC Rate: {{ getValue(alphataxcodes, "atc_code", doc_detail.atc_code, "atc_rate") }}</h4>
                    </v-flex>
                    
                    <v-flex>
                      <h4>Remarks: {{doc_detail.remarks}}</h4>
                    </v-flex>
                  </v-layout>
                </v-container>
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Analysis</th>
                        <th>Department</th>
                        <th>Branch</th>
                        <th>Section</th>
                        <th>Chart of Accounts</th>
                        <th>Debit</th>
                        <th>Credit</th>
                      </tr>
                    </thead>
                    <tbody v-if="batchDetails.document_type === 'CW'">
                      <tr
                        v-for="(detail, detail_key) in doc_detail.cw_details"
                        :key="detail.detail_key"
                      >
                        <td>{{ detail_key + 1 }}</td>
                        <td>
                          {{ getValue(payees, "id", detail.payee_id, "payee_desc") }}
                        </td>
                        <td>
                          {{ getValue(depts, "id", detail.department_id, "dept_code") }}
                        </td>
                        <td>
                          {{ getValue(branches, "id", detail.branch_id, "brnc_code") }}
                        </td>
                        <td>
                          {{ getValue(sections, "id", detail.section_id, "section_code") }}
                        </td>
                        <td>
                          {{ getValue(coas, "acct_code", detail.account_code, "acct_desc") }}
                        </td>
                        <td class="text-right">
                          {{formatAmount(detail.debit_amount)}}
                        </td>
                        <td class="text-right">
                          {{formatAmount(detail.credit_amount)}}
                        </td>
                      </tr>
                      <tr>
                        <td colspan="4">
                          <b>Total</b>
                        </td>
                        <td>&nbsp;</td>
                        <td class="text-right">
                        </td>
                        <td class="text-right">
                          <b>{{formatAmount(doc_detail.overallDebit)}}</b>
                        </td>
                        <td class="text-right">
                          <b>{{formatAmount(doc_detail.overallCredit)}}</b>
                        </td>
                        <td>&nbsp;</td>
                      </tr>
                    </tbody>
										<tbody v-if="batchDetails.document_type !== 'CW'">
                      <tr
                        v-for="(detail, detail_key) in doc_detail.details"
                        :key="detail.detail_key"
                      >
                        <td>{{ detail_key + 1 }}</td>
                        <td>
                          {{ getValue(payees, "id", detail.payee_id, "payee_desc") }}
                        </td>
                        <td>
                          {{ getValue(depts, "id", detail.department_id, "dept_code") }}
                        </td>
                        <td>
                          {{ getValue(branches, "id", detail.branch_id, "brnc_code") }}
                        </td>
                        <td>
                          {{ getValue(sections, "id", detail.section_id, "section_code") }}
                        </td>
                        <td>
                          {{ getValue(coas, "acct_code", detail.account_code, "acct_desc") }}
                        </td>
                        <td class="text-right">
                          {{formatAmount(detail.debit_amount)}}
                        </td>
                        <td class="text-right">
                          {{formatAmount(detail.credit_amount)}}
                        </td>
                      </tr>
                      <tr>
                        <td colspan="4">
                          <b>Total</b>
                        </td>
                        <td>&nbsp;</td>
                        <td class="text-right">
                        </td>
                        <td class="text-right">
                          <b>{{formatAmount(doc_detail.overallDebit)}}</b>
                        </td>
                        <td class="text-right">
                          <b>{{formatAmount(doc_detail.overallCredit)}}</b>
                        </td>
                        <td>&nbsp;</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-container>
            </v-card>
          </v-container>
        </v-row>
      </v-container>
      <br/>
      <v-container>
        <v-layout>
          <v-flex md6>
            <h4>Prepared By: {{preparer}}</h4>
          </v-flex>
          <v-flex md6 v-if="batchDetails.approved_by">
            <h4>Approved By: {{ batchDetails.approved_by }}</h4>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <!-- <v-btn
        color="success"
        text
        @click="approveDisbursement = true"
      >
      {{getLabel(batchDetails.status)}}
      </v-btn>
      <v-btn
        color="red"
        text
        @click="rejectDisbursement = true"
      >
      Reject
      </v-btn> -->
      <!-- <v-btn
        color="primary"
        text
        @click="closeDialog(batchDetails.batch_number)"
      >
      Dismiss
      </v-btn> -->
      <v-btn color="primary" @click="redirectAction(batchDetails.batch_number, batchDetails.document_type)">
        Complete action on {{getTrxType(batchDetails.document_type)}} Module
      </v-btn>
    </v-card-actions>
    <div class="text-center">
      <v-dialog
        v-model="approveDisbursement"
        persistent max-width="600"
      >
        <v-card>
          <v-card-title
            class="headline primary white--text"
            primary-title
          >
            {{getLabel(batchDetails.status)}} Disbursement
          </v-card-title>
          <br>
          <v-card-text>
            Are you sure you want to {{getLabel(batchDetails.status)}} this disbursement from Batch No. {{batchDetails.batch_number}}?
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="approve(batchDetails.batch_number, documentDetails[0].status)"
            >
              {{getLabel(batchDetails.status)}}
            </v-btn>
            <v-btn
              color="red"
              text
              @click="approveDisbursement = false"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div class="text-center">
      <v-dialog
        v-model="rejectDisbursement"
        persistent max-width="600"
      >
        <v-card>
          <v-card-title
            class="headline primary white--text"
            primary-title
          >
            Reject Disbursement
          </v-card-title>
          <br>
          <v-card-text>
            Are you sure you want to reject this disbursement from Batch No. {{batchDetails.batch_number}}?
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="rejectDisbursementReason = true"
            >
              Reject
            </v-btn>
            <v-btn
              color="red"
              text
              @click="rejectDisbursement = false"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div class="text-center">
      <v-dialog
        v-model="rejectDisbursementReason"
        persistent max-width="600"
      >
        <v-card>
          <v-card-title
            class="headline primary white--text"
            primary-title
          >
            Reason for Rejection
          </v-card-title>
          <br>
          <v-card-text>
            Please state the reason:
            <v-text-field v-model="rejectRemarks" label="Remarks" required></v-text-field>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="reject(batchDetails.batch_number, documentDetails[0].status)"
            >
              Reject
            </v-btn>
            <v-btn
              color="red"
              text
              @click="rejectDisbursementReason = false"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <v-snackbar
      v-model="snackbar"
      right
    >
      {{ error_message }}
      <v-btn
        color="pink"
        text
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </v-snackbar>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex';
import numeral from 'numeral';
import jsonToFormData from 'json-form-data';
import { format } from 'date-fns';
export default {
	props: {
		batchDetails: Array,
		documentDetails: Array
	},
	data() {
		return {
			upload_files: '',
			upload_file: [],
			image_title: [],
			delete_file: [],
			seenAttachedFile:[],
			attachHeaders: [
				{
					text: 'File Name',
					align: 'left',
					sortable: false,
					value: 'title'
				},
				{ 
					text: 'Actions',
					align: 'right',
					sortable: false,
					value: 'file',
				},
			],
			panel: 0,
			detailHeaders:[
				{ text: 'Analysis', value: 'payee_id' },
				{ text: 'Department', value: 'department_id' },
				{ text: 'Branch', value: 'branch_id' },
				{ text: 'Section', value: 'section_id' },
				{ text: 'Chart of Accounts', value: 'account_code' },
				{ text: 'Debit', value: 'debit_amount' },
				{ text: 'Credit', value: 'credit_amount' },
			],
			error_message: 'Please view attached file before approving.',
			snackbar: false,
			approveDisbursement: false,
			rejectDisbursement: false,
			rejectDisbursementReason: false,
			rejectRemarks: '',
			partner_bank_files: [],
			batch_number_rules: [
				v => !!v || 'Batch Number is required',
			],
			date_rules: [
				v => !!v || 'Date is required',
			],
			payee_rules: [
				v => !!v || 'Payee is required',
			],
			bank_rules: [
				v => !!v || 'Bank is required',
			]
		};
	},
	computed: {
		...mapGetters({
			coas: 'coa/coas',
			depts: 'dept/depts',
			branches: 'branch/branches',
			sections: 'section/sections',
			payees: 'payefile/payefiles',
			banks: 'bank/banks',
			bpbanks: 'bpbank/bpbanks',
			alphataxcodes: 'alphataxcode/alphataxcodes',
			// transactions: 'transaction/transactions',
			headers: 'trxdisb/headers',
			currUser: 'auth/currUser',
			approvingofficers: 'approvingofficer/approvingofficers',
			viewDialog: 'trxdisb/viewDialog',
			regulartrx: 'regularTxns/regulartxns',
			preparer: 'trxdisb/preparer',
			trxdisbDetailed: 'trxdisb/trxdisbDetailed'
		})
	},
	watch: {
		batchDetails: {
			handler(val) {
				this.$emit('update:batchDetails', val);
			},
			deep: true
		},
		documentDetails: {
			handler(val) {
				//this.documentDetails.details.filter(det => det.batch_number = this.batchDetails.batch_number);
				this.$emit('update:documentDetails', val);
			},
			deep: true
		}
	},
	mounted() {
		this.$store.dispatch('coa/getCoas');
		this.$store.dispatch('dept/getDepts');
		this.$store.dispatch('branch/getBranches');
		this.$store.dispatch('section/getSections');
		this.$store.dispatch('payefile/getPayefiles');
		this.$store.dispatch('bpbank/getBpbanks');
		this.$store.dispatch('alphataxcode/getAlphataxcodes');
		this.$store.dispatch('regularTxns/getregularTxns');
		this.$store.dispatch('approvingofficer/getApprovingOfficers');
		// this.$store.dispatch('transaction/getTransactions');		
	},
	methods: {
		approve(batch_number, current_status) {
			let attachIsClicked = true;
			let attachment = [];
			let detail = [];
			this.error_message = '';

			this.trxdisbDetailed.forEach(i => {
				attachment.push(i.path);
			});

			attachment.map(i => {
				let detailData = {document_number: i, matched: this.seenAttachedFile.includes(i)};
				detail.push(detailData);
			});

			detail.forEach(details => {
				if(!details.matched){
					attachIsClicked = false;
				}
			});

			if(attachIsClicked){
				let data = {};
				let regularTrx = {};

				data.batch_number = batch_number;
				data.value = {};

				let filterByBatchNumber = this.documentDetails.filter(header => {
					return header.batch_number === batch_number;
				});

				let filterByRegTrx = this.documentDetails.filter(header => {
					return header.regular_trx_id > 0 && header.regular_trx_id !== process.env.VUE_APP_REGULAR_TRX_ID_PLACEHOLDER;
				});

				const totalAmount = filterByBatchNumber.reduce((sum, header) => {
					return sum + parseFloat(header.amount);
				}, 0);
        
        
				data.value._method = 'PUT';
				if(this.upload_files.length != 0){
					data.value.image_title = this.image_title;
					data.value.upload_file = this.upload_file;
					for( var i = 0; i < this.upload_files.length; i++ ){
						let file = this.upload_files[i];
						data.value.image_title.push(file.name);
						data.value.upload_file.push(file);
					}
					data.value.delete_file = this.delete_file;
					for( var j = 0; j < this.trxdisbDetailed.length; j++ ){
						data.value.delete_file.push(this.trxdisbDetailed[j].filename);
					}
				}
        
				var options = {
					initialFormData: new FormData(),
					showLeafArrayIndexes: true,
					includeNullValues: false,
					mapping: function(value) {
						if (typeof value === 'boolean') {
							return +value ? '1': '0';
						}
						return value;
					}
				};
        
				var convertedFormData = '';

				let is_attachment_uploaded = data.value.upload_file;
				let current_user_name = this.currUser.user_last_name + ', ' + this.currUser.user_first_name;

				if (current_status === 2) {
					data.value.status = 6;
					data.value.headers = [];
					data.value.processing = 0;
					data.value.approved_remarks = '';
					convertedFormData = jsonToFormData(data.value, options);
					this.$store.dispatch('trxdisb/updateTrxdisbStatus', {batch_number: data.batch_number, value: convertedFormData});
				} else {
					switch(current_status) {
					case 3:
						if (this.preparer == current_user_name) {
							this.error_message = 'Cannot be approved by the same Preparer and Approver';
							this.approveDisbursement = false;
							this.snackbar = true;
						} else {
							if (totalAmount > 100000 && filterByRegTrx.length < 1) {
								//Go to Approver 2
								data.value.status = 4;
								data.value.headers = [];
								data.value.processing = 0;
								data.value.approved_remarks = '';
								data.value.approved_by = this.currUser.id;
								data.value.document_type = this.batchDetails.document_type;
								convertedFormData = jsonToFormData(data.value, options);
								this.$store.dispatch('trxdisb/updateTrxdisbStatus', {batch_number: data.batch_number, value: convertedFormData});
							} else {
								//Go to Releasing
								this.documentDetails.filter(header => {
									this.regulartrx.filter(trxdata => {			
										if (trxdata.doc_number === header.document_number) {
											regularTrx = {
												id: trxdata.id,
												doc_number: trxdata.doc_number,
												is_approved: 1
											};

											data.regular_trx_id = trxdata.id;
											this.$store.dispatch('regularTxns/updateregularTxns', regularTrx);
										}
									});
								});
								data.value.status = 6;
								data.value.headers = [];
								data.value.processing = 0;
								data.value.approved_remarks = '';
								data.value.approved_by = this.currUser.id;
								data.value.document_type = this.batchDetails.document_type;
								
								convertedFormData = jsonToFormData(data.value, options);
								this.$store.dispatch('trxdisb/updateTrxdisbStatus', {batch_number: data.batch_number, value: convertedFormData});
							}	
						}
						break;
					case 4:
						if (this.preparer == current_user_name) {
							this.error_message = 'Cannot be approved by the same Preparer and Approver';
							this.approveDisbursement = false;
							this.snackbar = true;
						} else {
							if (totalAmount > 1000000 && filterByRegTrx.length < 1) {
								//Go to Approver 3
								data.value.status = 5;
								data.value.headers = [];
								data.value.processing = 0;
								data.value.approved_remarks = '';
								data.value.approved_by = this.currUser.id;
								data.value.document_type = this.batchDetails.document_type;

								convertedFormData = jsonToFormData(data.value, options);
								this.$store.dispatch('trxdisb/updateTrxdisbStatus', {batch_number: data.batch_number, value: convertedFormData});
							} else {
								//Go to Releasing
								this.documentDetails.filter(header => {
									this.regulartrx.filter(trxdata => {			
										if (trxdata.doc_number === header.document_number) {
											regularTrx = {
												id: trxdata.id,
												doc_number: trxdata.doc_number,
												is_approved: 1
											};

											data.regular_trx_id = trxdata.id;
											this.$store.dispatch('regularTxns/updateregularTxns', regularTrx);
										}
									});
								});
								data.value.status = 6;
								data.value.headers = [];
								data.value.processing = 0;
								data.value.approved_remarks = '';
								data.value.approved_by = this.currUser.id;
								data.value.document_type = this.batchDetails.document_type;

								convertedFormData = jsonToFormData(data.value, options);
								this.$store.dispatch('trxdisb/updateTrxdisbStatus', {batch_number: data.batch_number, value: convertedFormData});
							}
						}
						break;
					case 5:
						if (this.preparer == current_user_name) {
							this.error_message = 'Cannot be approved by the same Preparer and Approver';
							this.approveDisbursement = false;
							this.snackbar = true;
						} else {
							this.documentDetails.filter(header => {
								this.regulartrx.filter(trxdata => {			
									if (trxdata.doc_number === header.document_number) {
										regularTrx = {
											id: trxdata.id,
											doc_number: trxdata.doc_number,
											is_approved: 1
										};

										data.regular_trx_id = trxdata.id;
										this.$store.dispatch('regularTxns/updateregularTxns', regularTrx);
									}
								});
							});

							data.value.headers = [];
							data.value.status = 6;
							data.value.processing = 0;
							data.value.approved_remarks = '';
							data.value.approved_by = this.currUser.id;
							data.value.document_type = this.batchDetails.document_type;
							convertedFormData = jsonToFormData(data.value, options);
							this.$store.dispatch('trxdisb/updateTrxdisbStatus', {batch_number: data.batch_number, value: convertedFormData});
						}
						break;
					case 8:
						data.value.status = 6;
						data.value.processing = 0;
						data.value.approved_remarks = '';
						data.value.approved_by = this.currUser.id;
						data.value.document_type = this.batchDetails.document_type;
						data.value.headers = [];
						convertedFormData = jsonToFormData(data.value, options);
						this.$store.dispatch('trxdisb/updateTrxdisbStatus', {batch_number: data.batch_number, value: convertedFormData});
						break;
					case 6:						
						data.value.status = 7;
						data.value.processing = 0;
						data.value.approved_remarks = '';
						data.value.approved_by = this.currUser.id;
						data.value.document_type = this.batchDetails.document_type;
						data.value.headers = [];

						if (is_attachment_uploaded !== undefined) {
							if (this.batchDetails.document_type === 'CW') {
								data.value.headers = this.partner_bank_files;


								if (this.partner_bank_files.length < 1) {
									this.error_message = 'Please upload a partner bank file to release this disbursement.';
									this.approveDisbursement = false;
									this.snackbar = true;
								} else {									
									convertedFormData = jsonToFormData(data.value, options);
									this.$store.dispatch('trxdisb/updateTrxdisbStatus', {batch_number: data.batch_number, value: convertedFormData});
								}
							} else {
								convertedFormData = jsonToFormData(data.value, options);
								this.$store.dispatch('trxdisb/updateTrxdisbStatus', {batch_number: data.batch_number, value: convertedFormData});
							}
						} else {
							this.error_message = 'Please upload a file to release this disbursement.';
							this.approveDisbursement = false;
							this.snackbar = true;
						}
						break;
					default:
						data.value.status = 7;
						data.value.processing = 0;
						data.value.approved_remarks = '';
						data.value.document_type = this.batchDetails.document_type;
						convertedFormData = jsonToFormData(data.value, options);
						this.$store.dispatch('trxdisb/updateTrxdisbStatus', {batch_number: data.batch_number, value: convertedFormData});
					}
				}

				if (current_status === 6) {
					if (is_attachment_uploaded !== undefined) {
						if (this.batchDetails.document_type === 'CW') {
							if (this.partner_bank_files.length > 0) {
								this.$store.dispatch('trxdisb/setViewDialog', false);
							}
						} else {
							this.$store.dispatch('trxdisb/setViewDialog', false);
						}
					}
				} else {
					if (this.preparer !== current_user_name) {  
						this.$store.dispatch('trxdisb/setViewDialog', false);
					}
				}
			} else {
				this.error_message = 'Please view attached file before approving.';
				this.approveDisbursement = false;
				this.snackbar = true;
			}
		},
		reject(batch_number, current_status) {
			let attachIsClicked = true;
			let attachment = [];
			let detail = [];
			this.error_message = '';

			let current_user_name = this.currUser.user_last_name + ', ' + this.currUser.user_first_name;

			this.trxdisbDetailed.forEach(i => {
				attachment.push(i.path);
			});

			attachment.map(i => {
				let detailData = {document_number: i, matched: this.seenAttachedFile.includes(i)};
				detail.push(detailData);
			});

			detail.forEach(details => {
				if(!details.matched){
					attachIsClicked = false;
				}
			});

			if(attachIsClicked){

				let data = {};
				data.batch_number = batch_number;
				data.value = {
					status: 1,
					processing: 0,
					approved_remarks: this.rejectRemarks,
					document_type: this.batchDetails.document_type,
					_method: 'PUT'
				};
			
				var options = {
					initialFormData: new FormData(),
					showLeafArrayIndexes: true,
					includeNullValues: false,
					mapping: function(value) {
						if (typeof value === 'boolean') {
							return +value ? '1': '0';
						}
						return value;
					}
				};

				let convertedFormData = jsonToFormData(data.value, options);

				if (current_status === 6) { 
					this.$store.dispatch('trxdisb/updateTrxdisbStatus', {batch_number: data.batch_number, value: convertedFormData});
					this.$store.dispatch('trxdisb/setViewDialog', false);
				} else {
					if (this.preparer == current_user_name) {
						this.error_message = 'Cannot be rejected by the same Preparer and Approver';
						this.rejectDisbursement = false;
						this.rejectDisbursementReason = false;
						this.snackbar = true;
					} else {
						this.$store.dispatch('trxdisb/updateTrxdisbStatus', {batch_number: data.batch_number, value: convertedFormData});
						this.$store.dispatch('trxdisb/setViewDialog', false);
					}
				}
			} else {
				this.error_message = 'Please view attached file before rejecting.';
				this.rejectDisbursement = false;
				this.rejectDisbursementReason = false;
				this.snackbar = true;
			}
		},
		closeDialog(batch_number) {
			this.documentDetails.forEach((header) => {
				if (header.batch_number === batch_number) {
					let data = {};
										
					data.batch_number = header.batch_number;
					data.value = {
						processing: 0,
						status: header.status,
						_method:'PUT'
					};
          
					var options = {
						initialFormData: new FormData(),
						showLeafArrayIndexes: true,
						includeNullValues: false,
						mapping: function(value) {
							if (typeof value === 'boolean') {
								return +value ? '1': '0';
							}
							return value;
						}
					};
					let convertedFormData = jsonToFormData(data.value, options);
					this.$store.dispatch('trxdisb/updateTrxdisbStatus', {batch_number: data.batch_number, value: convertedFormData});
				}
			});
			this.$store.dispatch('trxdisb/setViewDialog', false);
		},
		formatAmount(amount){
			return numeral(amount).format('0,0.00');
		},
		getValue(tableData, columnId, id, column){
			for (var i=0; i < tableData.length; i++) {
				if (tableData[i][columnId] === id) {
					return tableData[i][column];
				}
			}
		},
		getTrxType(type){
			switch(type){
			case 'DM':
			case 'CV':
			case 'CW':
			case 'OB':
				return 'Disbursement';
			case 'JE':
				return 'General Journal';
			case 'CR':
				return 'Cash Receipt';
			}
		},
		getDocuType(type){
			switch(type){
			case 'DM':
				return 'Debit Memo';
			case 'CV':
				return 'Cheque Voucher';
			case 'CW':
				return 'Cheque Write';
			case 'OB':
				return 'Online Banking';
			case 'JE':
				return 'Journal Entry';
			case 'CR':
				return 'Cash Receipt';
			}
		},
		getTransType(type){
			switch(type){
			case 1:
				return 'Regular';
			case 2:
				return 'Non-Regular';
			}
		},
		getLabel(status){
			if(status == 6){
				return 'Release';
			} else {
				return 'Approve';
			}
		},
		printDM() {
			let item = this.documentDetails[0];
			let routeData = this.$router.resolve({path: '/preview-trx/'+btoa(JSON.stringify(item)), data: item});
			window.open(routeData.href, '_blank'); 
		},
		printCheque(){
			let item = this.documentDetails[0];
			let routeData = this.$router.resolve({path: '/preview-cheque/'+btoa(JSON.stringify(item)), data: item});
			window.open(routeData.href, '_blank'); 

			let routeDataVoucher = this.$router.resolve({path: '/preview-voucher/'+btoa(JSON.stringify(item)), data: item});
			window.open(routeDataVoucher.href, '_blank'); 
		},
		handleFileUploads(){
			this.upload_files = this.$refs.files.files;
		},
		handleTextFileUpload() {
			let textFile = this.$refs.partnerBankFiles.files[0];
			
			
			var fileReader = new FileReader();

			fileReader.onload = (event) => { 
				var lines = event.target.result.split('\n');
				let payload = [];

				for (var line = 0; line < lines.length; line++) {
					let getVoucherNo = lines[line].slice(6 - 1, 11);
					let getVoucherDate = lines[line].slice(13 - 1, 23);
					let getChequeNo = lines[line].slice(25 - 1, 34);
					let getChequeDate = lines[line].slice(36 - 1, 46);
					// let getReferenceNo = lines[line].slice(70 - 1, 81);

					if (lines[line] !== '') {
						payload.push({
							batch_number: this.batchDetails.batch_number,
							reference_number: this.batchDetails.document_type === 'CW' ?  this.documentDetails[line].reference_number  : this.documentDetails[line].document_number,
							cheque_number: getChequeNo,
							cheque_date: format(new Date(getChequeDate), 'yyyy-MM-dd'),
							voucher_date: format(new Date(getVoucherDate), 'yyyy-MM-dd'),
							document_number: getVoucherNo,
							document_date: format(new Date(getVoucherDate), 'yyyy-MM-dd'),
						});
					}
				}

				this.$data.partner_bank_files = payload;
			};
			
			// console.log(this.partner_bank_files);

			fileReader.readAsText(textFile); 
		},
		viewItem(item) {
			const details = this.trxdisbDetailed.filter(detail => {
				if(detail.path === item){
					detail.seen = true;
				}
				return detail;
			});
			this.$store.commit('trxdisb/TRXDISBS_DETAIL', details);

			//let url = process.env.VUE_APP_STORAGE_URL + '/' + this.billingDetails[0].billing_invoice_no + '/' + item;
			let path = atob(item);
			fetch(path)
				.then(res => res.blob()) // Gets the response and returns it as a blob
				.then(blob => {
					const objectURL = URL.createObjectURL(blob);
					window.open(objectURL, '_blank');
				});
			// window.open(path, '_blank');
			this.seenAttachedFile.push(item);
		},
		redirectAction(batch_number, trx_type) {
			this.$store.dispatch('trxdisb/setViewDialog', false);

			switch(trx_type){
			case 'DM':
			case 'CV':
			case 'CW':
			case 'OB':
				this.$router.push({ name: 'trxdisb', params: {action: 'view', batch_number: batch_number }});
				break;
			case 'JE':
				this.$router.push({ name: 'trx-gen-journal', params: {action: 'view', batch_number: batch_number }});
				break;
			case 'CR':
				this.$router.push({ name: 'trxcr', params: {action: 'view', batch_number: batch_number }});
				break;
			}
		},
	}
};
</script>