<template>
  <v-container>
    <v-card>
      <v-card-title align="left" class="headline primary search-result-title">
				<div class="title-container">
					<h5>Transaction Global Search</h5>
				</div>
				<v-text-field
					flat
					solo-inverted
					hide-details
					dark
					prepend-inner-icon="search"
					label="Search for Batch Number then press the 'Enter' key"
					class="hidden-sm-and-down search-bar-app searchbar-page"
					v-model="searchValue"
					@keyup="search"
				></v-text-field>
      </v-card-title>
			<v-data-table
				:items="searchResults"
				:headers="headers"
				sort-by="document_number"
				class="elevation-1"
				:loading="loading"
				loading-text="Searching... Please wait"
			>
				<template v-slot:top>
					<v-toolbar flat color="white">
						<v-toolbar-title v-show="!loading">Search Result(s):</v-toolbar-title>
						<v-toolbar-title v-show="loading">Searching...</v-toolbar-title>
						<v-spacer></v-spacer>
					</v-toolbar>
				</template>
				<template v-slot:[`item.action`]="{ item }">
					<!-- <template v-if="item.status_code === 10">
						<v-icon color="red" class="mr-2" small
							>mdi-close-octagon-outline</v-icon>
					</template> -->
					<template>
						<v-icon color="primary" class="mr-2" small @click="redirectEditItem(item)"
							>edit</v-icon
						>
					</template> <!-- v-if="item.preparer === currentUserFullName && item.status_code === 1"-->
					<!-- <template v-else-if="item.preparer !== currentUserFullName && item.status_code === 1">
						<v-icon color="red" class="mr-2" small
							>mdi-pen-off</v-icon>
					</template>
					<template v-if="item.status_code !== 7 && item.status_code !== 6 && item.status_code !== 1 && item.status_code !== 10 && item.status_code !== 20">
						<template v-if="currUser.max_amount !== null">
							<template>
								<v-icon color="primary" class="mr-2" small @click="viewItem(item)"
									>mdi-clipboard-check-multiple-outline</v-icon
								>
							</template>
						</template>
					</template> -->


					<!-- <v-icon color="primary" v-if="item.status_code === 7" class="mr-2" small @click="cancelEntry(item)"
						>mdi-close-circle-outline</v-icon
					> -->
					<!-- <v-icon color="primary" v-if="item.status_code === 7" class="mr-2" small @click="downloadItem(item)"
						>mdi-folder-download-outline</v-icon
					>
					<v-icon color="primary" v-if="item.status_code === 7" class="mr-2" small @click="generateBIRForm(item)"
						>mdi-book-information-variant</v-icon
					>
					<v-icon color="primary" v-if="item.status_code === 7 && item.document_type != 'CV'" class="mr-2" small @click="printItem(item)"
						>mdi-printer-eye</v-icon
					>
					<v-icon color="primary" v-if="item.status_code === 7 && item.document_type == 'CV'" class="mr-2" small @click="printItemCheque(item)"
						>mdi-printer-eye</v-icon
					>
					<v-icon color="primary" v-if="item.status_code === 20 && item.preparer === currentUserFullName" class="mr-2" small @click="restoreItemSelect(item)"
						>mdi-restore</v-icon
					>
					<v-icon color="red" v-if="item.status_code === 20 && item.preparer !== currentUserFullName" class="mr-2" small
						>mdi-close-octagon-outline</v-icon
					>-->
				</template>
			</v-data-table>
			<div class="text-center">
				<v-dialog
					v-model="viewDialog"
					:items="batchDetails"
					:documentDetails="documentDetails"
					width="1200"
				>
					<v-trx-detail ref="detailTrxdisb" :batchDetails.sync="batchDetails" :documentDetails.sync="documentDetails" :viewDialog.sync="viewDialog"></v-trx-detail>
				</v-dialog>
			</div>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import { format, getYear, parseISO, getMonth } from 'date-fns';
import numeral from 'numeral';
import detailSearch from './SearchDetails.vue';
import jsonToFormData from 'json-form-data';

export default {
	name: 'SearchResult',
	components: {
		'v-trx-detail': detailSearch,
	},
	data() {
		return {
			month_year: '',
			searchValue: '',
			searchResults: [],
			batchDetails: [],
			documentDetails: [],
			attachmentDialog: [],
			loading: false,
			currentUserFullName: '',
			userData: [],
			nextPage: null,
			headers: [
				{
					text: 'Batch Number',
					align: 'left',
					sortable: false,
					value: 'batch_number',
				},
				{ text: 'Date Encoded', value: 'created_at' },
				{ text: 'Document Date', value: 'document_date', align: ' d-none'},
				{ text: 'Preparer', value: 'preparer', align: ' left' },
				// { text: 'Payee', value: 'payee' },
				{ text: 'Type of Transaction', value: 'document_type' },
				{ text: 'Status Code', value: 'status_code', align: ' d-none' },
				{ text: 'Status', value: 'status' },
				// { text: 'Type of Transaction', value: 'regular_trx' },
				// { text: 'PETNET Bank', value: 'bank_name' },
				{ text: 'Amount', value: 'amount', align: 'right' },
				{ text: 'Actions', value: 'action', align: 'center' },
			],
		};
	},
	computed: {
		...mapGetters({
			users: 'user/users',
			transactionResults: 'trxdisb/transactionResults',
			currUser: 'auth/currUser',
			viewDialog: 'trxdisb/viewDialog',
			trxdisbs: 'trxdisb/trxdisbs',
			trxdisbDetails: 'trxdisb/trxdisbDetails',
		}),
	},
	watch: {
		users: {
			handler(){
				this.getData();
			}
		},
		trxdisbs: {
			handler() {
			},
			deep: true
		},
		transactionResults: {
			handler(val) {
				let totalAmount = val.reduce((sum, item) => {
					if (item.amount === '') {
						item.amount = 0;
					}

					return sum + parseFloat(item.amount);
				}, 0);

				let uniqueResult = [];

				val.forEach((item) => {

					// let trxPreparer = this.trxdisbs.filter(list => {
					// 	return list.batch_number === item.batch_number;
					// });
					
					let checker = uniqueResult.findIndex(result => result.batch_number == item.batch_number);
					if(checker <= -1){
						let newItem = {
							batch_number: item.batch_number,
							created_at: format(new Date(item.created_at), 'yyyy-MM-dd'),
							document_type: item.document_type,
							document_date: item.document_date,
							status_code: item.status,
							status: this.displayStatus(item.status),
							amount: this.formatAmount(totalAmount),
							preparer: this.getPreparer(item.edit_by)
						};

						uniqueResult.push(newItem);
					}
				});
				
				this.searchResults = uniqueResult;
				this.loading = false;
			},
			deep: true
		},
		trxdisbDetails: {
			handler(val) {
				this.$data.batchDetails.date = format(new Date(), 'yyyy-MM-dd');
				this.$data.batchDetails.document_type = val[0].document_type;
				this.$data.batchDetails.is_confidential = val[0].is_confidential;
				this.$data.batchDetails.bank_account_id = val[0].bank_account_id;


								

				val.forEach(docData => {
					let filterDetails = [];

					if (docData.document_type === 'CW') {
						filterDetails = docData.cw_details.filter(detail => detail.document_number === docData.document_number);
					} else {
						filterDetails = docData.details.filter(detail => detail.document_number === docData.document_number);
					}


					//computes the total debit in a specific document
					const totalDebit = filterDetails.reduce((sum, details) => {
						if (details.debit_amount === '') {
							details.debit_amount = 0;
						}

						return sum + parseFloat(details.debit_amount);
					}, 0);

					//computes the total credit in a specific document
					const totalCredit = filterDetails.reduce((sum, details) => {
						if (details.credit_amount === '') {
							details.credit_amount = 0;
						}

						return sum + parseFloat(details.credit_amount);
					}, 0);

					docData.overallDebit = totalDebit;
					docData.overallCredit = totalCredit;

					docData.disbursement_type = docData.regular_trx_id > 0 ? 1 : 2;
					docData.regular_trx_type = (docData.disbursement_type == 1 && docData.regular_trx_id > 0) ? 1 : 2;
					docData.regular_trx_selected = docData.regular_trx_id !== process.env.VUE_APP_REGULAR_TRX_ID_PLACEHOLDER ? docData.regular_trx_id : 0;
				});

				this.$data.documentDetails = val;

			},
			deep: true
		},
	},
	mounted() {
		this.$store.dispatch('user/getUsers');
		let date = format(new Date(), 'yyyy-MM-dd');
		let month = getMonth(parseISO(date)) + 1;
		let year = getYear(parseISO(date));
		let i = (month.toString().length == 2 ? '-' : '-0');
		this.month_year = year + i + month;
		this.$store.dispatch('trxdisb/getTrxdisbs', {document_date: this.month_year + '-01'});
		this.$store.dispatch('trxdisb/setViewDialog', false);

		this.$data.currentUserFullName = this.currUser.user_last_name + ', ' + this.currUser.user_first_name;

		if (localStorage.getItem('searched_item')) {
			let payload = {
				keyCode: 13,
				target: {
					value: localStorage.getItem('searched_item')
				}
			};
			this.search(payload);
		}
	},
	methods: {
		search(e) {
			if (e.keyCode === 13) {
				this.searchResults = [];
				let value = e.target.value;
				this.loading = true;
				this.$store.dispatch('trxdisb/getTransaction', value);
				this.searchValue = '';
			}
		},
		formatAmount(amount){
			return numeral(amount).format('0,0.00');
		},
		displayStatus(status) {
			switch(status) {
			case 1:
				return 'Saved as Draft';
			case 3:
			case 4:
			case 5:
				return 'Pending Approval';
			case 6:
				return 'For Releasing';
			case 7:
				return 'Approved / Released';
			case 10:
				return 'Cancelled';
			case 20:
				return 'Archived';
			}
		},
		async viewItem(item) {
			this.batchDetails = [];
			this.documentDetails = [];
			this.batchDetails.batch_number = item.batch_number;
			this.batchDetails.document_type = item.document_type;
			this.batchDetails.document_date = item.document_date;
			this.batchDetails.total_amount = item.total_amount;
			this.batchDetails.approved_by = item.approved_by; 
			this.batchDetails.status = item.status;
			
			let disbList = this.trxdisbs;
			disbList = Object.values(this.trxdisbs);

			let trxPreparer = disbList.filter(list => {
				return list.batch_number === item.batch_number;
			});

			this.$store.dispatch('trxdisb/setEditedIndex', disbList.indexOf(item));
			this.$store.dispatch('trxdisb/setPreparer', trxPreparer[0].preparer);
			this.$store.dispatch('trxdisb/getTrxdisb', item.batch_number);
			await this.$store.dispatch('trxdisb/getDetails', item.batch_number);
		},
		redirectEditItem(item) {
			let document_type = item.document_type;
			let batch_number = item.batch_number;

			switch(document_type){
			case 'DM':
			case 'CV':
			case 'CW':
			case 'OB':
				this.$router.push({ name: 'trxdisb', params: {action: 'draft', batch_number: batch_number }});
				break;
			case 'JE':
				this.$router.push({ name: 'trx-gen-journal', params: {action: 'draft', batch_number: batch_number }});
				break;
			case 'CR':
				this.$router.push({ name: 'trxcr', params: {action: 'draft', batch_number: batch_number }});
				break;
			}
		},
		generateBIRForm(item) {
			this.$router.push({
				name: 'bir-form',
				params: {
					data: item
				} 
			});
		},
		async downloadItem(item) {
			this.$data.batchDetails = [];
			this.documentDetails = [];
			this.$data.batchDetails.batch_number = item.batch_number;
			this.$data.batchDetails.total_amount = item.total_amount;

			let disbList = this.trxdisbs;
			disbList = Object.values(this.trxdisbs);

			this.$store.dispatch('trxdisb/setEditedIndex', disbList.indexOf(item));
			this.$store.dispatch('trxdisb/setAttachmentDialog', true);
			await this.$store.dispatch('trxdisb/getDetails', item.batch_number);
		},
		printItem(item) {
			let routeData = this.$router.resolve({path: '/preview-trx/'+btoa(JSON.stringify(item)), data: item});
			window.open(routeData.href, '_blank'); 
		},
		printItemCheque(item){
			let routeData = this.$router.resolve({path: '/preview-cheque/'+btoa(JSON.stringify(item)), data: item});
			window.open(routeData.href, '_blank'); 

			let routeDataVoucher = this.$router.resolve({path: '/preview-voucher/'+btoa(JSON.stringify(item)), data: item});
			window.open(routeDataVoucher.href, '_blank'); 
		},
		cancelEntry(item) {
			let document_type = item.document_type;
			let transaction_type = '';

			switch(document_type){
			case 'DM':
			case 'CV':
			case 'CW':
			case 'OB':
				transaction_type = 'Disbursement';
				break;
			case 'JE':
				transaction_type = 'General Journal';
				break;
			case 'CR':
				transaction_type = 'Cash Receipt';
				break;
			}

			this.$swal.fire({
				title: 'Cancel '+transaction_type+' Entry No. ' + item.batch_number,
				text: 'Are you sure you want to cancel this entry?',
				type: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'CANCEL '+transaction_type+' ENTRY',
				cancelButtonText: 'CANCEL'
			}).then(result => {
				if (result.value) {
					let data = {};
										
					data.batch_number = item.batch_number;
					data.value = {
						processing: 0,
						status: 10,
						approved_remarks: '',
						document_type: document_type,
						_method: 'PUT'
					};
					
					var options = {
						initialFormData: new FormData(),
						showLeafArrayIndexes: true,
						includeNullValues: false,
						mapping: function(value) {
							if (typeof value === 'boolean') {
								return +value ? '1': '0';
							}
							return value;
						}
					};

					let convertedFormData = jsonToFormData(data.value, options);
					this.$store.dispatch('trxdisb/setisLoading', true);
					this.$store.dispatch('trxdisb/updateTrxdisbStatus', {batch_number: data.batch_number, value: convertedFormData});
				}
			});
		},
		restoreItemSelect(item) {
			this.$router.push({ name: 'trx-archive', params: {action: 'restore', batch_number: item.batch_number }});
		},
		async getData(){
			this.userData = this.users.data;
			this.nextPage = this.users.next_page_url;
			let page = 2;

			while (!this.users.data){
				await this.$store.dispatch('users/getData', page).then(response => {
					this.nextPage = response.data.data.next_page_url;
					response.data.data.data.forEach(details => {
						this.userData.push(details);
					});
				});
				page++;
			}
		},
		getPreparer(item){
			let user = this.userData.filter(det => det.id == item);
			return user[0].user_last_name +', ' + user[0].user_first_name;
		}
	}
};
</script>
<style scoped>
	.search-result-title {
		color: #FFFFFF;
	}

	.title-container {
		width: 50%;
	}

	
</style>
 